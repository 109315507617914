<template>
  <div>
    <!-- SM -->
    <template v-if="$vuetify.breakpoint.smAndDown">
      <v-card class="mx-auto overflow-hidden" height="100%">
        <v-app-bar
          :color="$blue"
          dark
          fixed
          hide-on-scroll
          src="@/assets/asr-logo-03.png"
          lazy-src="@/assets/asr-logo-03.png"
        >
          <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
        </v-app-bar>

        <v-navigation-drawer
          class="drawer global-blue"
          v-model="drawer"
          temporary
          :overlay-color="$blue"
        >
          <v-list nav dense>
            <v-list-item-group v-model="group">
              <template v-for="(item, index) in drawerMenuItems">
                <v-list-item
                  v-if="item.path != null || item.logo == 'mdi-home'"
                  :key="`${index}_`"
                  :to="
                    item.logo !== 'mdi-home'
                      ? { name: item.path }
                      : { name: 'portal' }
                  "
                >
                  <v-list-item-icon>
                    <v-icon>{{ item.logo }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{
                    item.title[locale]
                  }}</v-list-item-title>

                </v-list-item>
                <v-list-item
                  v-if="item.action && item.action == 'changeLang'"
                  :key="`${index}_`"
                  @click="changeLang"
                  :color="$orange"
                >
                  <v-list-item-icon>
                    <v-icon :color="$orange">mdi-translate</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title :color="$orange">{{
                    item.title[locale]
                  }}</v-list-item-title>
                </v-list-item>
              </template>

              <v-list-item> </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
      </v-card>
    </template>

    <!-- MD -->

    <div class="md-top d-none d-md-block d-lg-none">

    <router-link :to="{ name: 'portal'}">
          <v-img
            
            src="@/assets/asr-logo-01.png"
            lazy-src="@/assets/asr-logo-01.png"
            min-width="180"
            max-width="180"
            contain
          ></v-img>
        </router-link>
    </div>

    <v-app-bar
      app
      :color="globalBlue"
      class="top-nav-md d-none d-md-block d-lg-none"
     hide-on-scroll
      flat
    >

        <v-row>

          <v-spacer></v-spacer>
      <v-toolbar-items>
        <template v-for="(item, index) in menuItems">
          <v-btn
            v-if="item.path != null"
            class="mx-n1 global-blue"
            color="#251104"
            x-small
            text
            :key="index"
            :id="`${item.path}${index}md`"
            :to="item.path"
          >
          <p class="buttons-md">{{ item.title[locale] }}</p>    
          </v-btn>

          <v-menu 
          open-on-hover 
          dark 
          offset-y 
          :key="`sub_${index}`" 
          v-if="item.dropdown" 
          :activator="`#${item.path}${index}md`"
          class="submenu"
          >
              <v-list
              :color="$blue"
              >

                <v-list-item
                  v-for="(subitem, subindex) in item.dropdown"
                  :key="subindex"
                  :value="subindex"
                  :to="subitem.path"
                  active-class="active-submenu"
                >
                  <v-list-item-title>{{ subitem.title[locale] }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

          <v-btn
            v-if="item.action && item.action == 'changeLang'"
            class="mx-n1 global-blue"
            color="blue"
            x-small
            text
            :key="index"
            @click="changeLang"
          >
            <p class="buttons-lang-md">{{ item.title[locale] }}</p>
          </v-btn>
        </template>
      </v-toolbar-items>
        </v-row>
    </v-app-bar>

    <!-- LG -->
    <v-app-bar
      app
      :color="globalBlue"
      class="top-nav d-none d-lg-block d-xl-none"
      hide-on-scroll
      flat
    >


      <router-link :to="{ name: 'portal' }">
        <v-img
          src="@/assets/asr-logo-01.png"
          lazy-src="@/assets/asr-logo-01.png"
          min-width="220"
          max-width="220"
          contain
        ></v-img>
      </router-link>

      <v-row>
        <v-spacer> </v-spacer>

        <v-toolbar-items>
          <template v-for="(item, index) in menuItems">
            <v-btn
              v-if="item.path != null"
              class="global-blue"
              color="#251104"
              small
              text
              :key="index"
              :to="item.path"
              :id="`${item.path}${index}lg`"
            >
              <p class="buttons-lg">{{ item.title[locale] }}</p>
            </v-btn>


            <v-menu 
            open-on-hover 
            dark 
            offset-y 
            :key="`sub_${index}`" 
            v-if="item.dropdown" 
            :activator="`#${item.path}${index}lg`"
            >
                <v-list
                :color="$blue"
                >

                  <v-list-item
                    v-for="(subitem, subindex) in item.dropdown"
                    :key="subindex"
                    :value="subindex"
                    :to="subitem.path"
                    active-class="active-submenu"
                  >
                    <v-list-item-title>{{ subitem.title[locale] }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>


            <v-btn
              v-if="item.action && item.action == 'changeLang'"
              class="global-blue"
              color="#251104"
              small
              text
              :key="index"
              @click="changeLang"
            >
              <p class="buttons-lg-lang">{{ item.title[locale] }}</p>
            </v-btn>
          </template>
        </v-toolbar-items>
      </v-row>
    </v-app-bar>

    <!-- XL -->
    <v-app-bar
      app
      :color="globalBlue"
      class="top-nav d-none d-xl-block"
      hide-on-scroll
      flat
    >
      <v-toolbar-title>
        <router-link :to="{ name: 'portal' }">
          <v-img
            src="@/assets/asr-logo-01.png"
            lazy-src="@/assets/asr-logo-01.png"
            min-width="150"
            max-width="300"
            contain
          ></v-img>
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <template v-for="(item, index) in menuItems">
          <v-btn
            v-if="item.path != null"
            class="mx-1 global-blue"
            color="#251104"
            small
            text
            :key="index"
            :to="item.path"
            :id="`${item.path}${index}xl`"
          >
            <p class="buttons">{{ item.title[locale] }}</p>
          </v-btn>



          <v-menu 
            open-on-hover 
            dark 
            offset-y 
            :key="`sub_${index}`" 
            v-if="item.dropdown" 
            :activator="`#${item.path}${index}xl`"
            >
                <v-list
                :color="$blue"
                >

                  <v-list-item
                    v-for="(subitem, subindex) in item.dropdown"
                    :key="subindex"
                    :value="subindex"
                    :to="subitem.path"
                    active-class="active-submenu"
                  >
                    <v-list-item-title>{{ subitem.title[locale] }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>



          <v-btn
            v-if="item.action && item.action == 'changeLang'"
            class="mx-1 global-blue"
            color="blue"
            small
            text
            :key="index"
            @click="changeLang"
          >
            <p class="buttons-lang">{{ item.title[locale] }}</p>
          </v-btn>
        </template>
      </v-toolbar-items>
    </v-app-bar>
  </div>
</template>

<style scoped>
.drawer {
  position: fixed !important;
  height: 500px;
}
.nav-link {
  color: aliceblue;
  background: #002135;
}
.top-nav {
  height: 30px;
}
.top-nav-md {
  height: 30px;
  margin-top: 20px  !important;
}
.buttons-md {
  color: white;
  margin-top: 2.5vw;
  font-weight: 600;
  font-size: 0.9vw;
}
.buttons-lang-md {
  color: #f7941e;
  margin-top: 2.5vw;
  font-weight: 600;
  font-size: 0.9vw;
}
.buttons {
  color: white;
  margin-top: 15px;
  font-weight: 600;
}
.buttons-lg-lang {
  color: #f7941e;
  margin-top: 15px;
  font-weight: 600;
  font-size: 0.7vw;
}
.buttons-lg {
  color: white;
  margin-top: 15px;
  font-weight: 600;
  font-size: 0.7vw;
}
.buttons-lang {
  color: #f7941e;
  margin-top: 15px;
  font-weight: 600;
}
.v-btn--active {
  border-bottom: 5px solid #f7941e;
  margin-top: 3px;
}
.md-top {
  height: 50px;
  width: 100%;
  position: fixed;
  z-index: 100;
  background: #002135;
  justify-content: center;
}
.active-submenu {
  color: #f7941e;
}
.v-menu__content {
  border: 1px solid #f7941e;
  border-radius: 8px;
}
</style>

<script>
export default {
  name: "Header",
  data() {
    return {
      drawer: false,
      group: null,
      locales: ["fi", "en"],
      selected: "fi",
      globalBlue: "#002135",
      menuItems: [],
      loggedOut: [
        {
          title: { fi: "Portaali", en: "Portal" },
          logo: "mdi-home",
        },
        {
          title: { fi: "Kehittämisympäristöt", en: "Development environments" },
          path: "environments",
          component: "Environments",
          logo: "mdi-city-variant",
          dropdown: [
            {
              title: { fi: "Palveluteemat", en: "Service themes" },
              path: "themes",
              component: "Themes",
              logo: "mdi-pound-box",
            },
            {
              title: { fi: "Referenssit", en: "References" },
              path: "references",
              component: "References",
              logo: "mdi-view-list",
            },
            {
              title: { fi: "TRL-info", en: "TRL-info" },
              path: "info",
              component: "Info",
              logo: "mdi-information",
            },
            {
              title: { fi: "Yhteystiedot", en: "Contacts" },
              path: "contacts",
              component: "Contacts",
              logo: "mdi-contacts",
            },
          ]
        },
        /*{
          title: { fi: "Palveluteemat", en: "Service themes" },
          path: "themes",
          component: "Themes",
          logo: "mdi-pound-box",
        },*/
        {
          title: { fi: "Klusteri-info", en: "Cluster information" },
          path: "cluster",
          component: "Cluster",
          logo: "mdi-vector-circle",
        },
        {
          title: { fi: "Teollisuus 4.0", en: "Industry 4.0" },
          path: "industry",
          component: "ndustry",
          logo: "mdi-code-tags-check",
        },
        {
          title: { fi: "Digitalisaatio", en: "Go-Digital" },
          path: "go-digital",
          component: "GoDigital",
          logo: "mdi-code-tags-check",
        },

        /*{
          title: { fi: "Referenssit", en: "References" },
          path: "references",
          component: "References",
          logo: "mdi-view-list",
        },*/
        {
          title: { fi: "Työkalut", en: "Tools" },
          path: "tools",
          component: "Tools",
          logo: "mdi-tools",
        },


        {
          title: { fi: "Kirjaudu sisään", en: "Login" },
          path: "login",
          component: "Login",
          logo: "mdi-login",
        },
        {
          title: {
            fi: "IN ENGLISH",
            en: "SUOMEKSI",
          },
          action: "changeLang",
        },
      ],
      loggedIn: [
        {
          title: { fi: "Portaali", en: "Portal" },
          logo: "mdi-home",
        },
        {
          title: { fi: "Palveluteemat", en: "Service themes" },
          path: "themes",
          component: "Themes",
          logo: "mdi-pound-box",
        },
        {
          title: { fi: "Klusteri-info", en: "Cluster information" },
          path: "cluster",
          component: "Cluster",
          logo: "mdi-vector-circle",
        },
        {
          title: { fi: "Teollisuus 4.0", en: "Industry 4.0" },
          path: "industry",
          component: "ndustry",
          logo: "mdi-code-tags-check",
        },
        {
          title: { fi: "Go-Digital", en: "Go-Digital" },
          path: "go-digital",
          component: "GoDigital",
          logo: "mdi-code-tags-check",
        },
        {
          title: { fi: "Kehittämisympäristöt", en: "Development environments" },
          path: "environments",
          component: "Environments",
          logo: "mdi-city-variant",
        },
        {
          title: { fi: "Referenssit", en: "References" },
          path: "references",
          component: "References",
          logo: "mdi-view-list",
        },
        {
          title: { fi: "Työkalut", en: "Tools" },
          path: "tools",
          component: "Tools",
          logo: "mdi-tools",
        },
        {
          title: { fi: "TRL-info", en: "TRL-info" },
          path: "info",
          component: "Info",
          logo: "mdi-information",
        },
        {
          title: { fi: "Yhteystiedot", en: "Contacts" },
          path: "contacts",
          component: "Contacts",
          logo: "mdi-contacts",
        },
        {
          title: { fi: "Kirjaudu ulos", en: "Logout" },
          path: "logout",
          component: "logout",
          logo: "mdi-logout",
        },

        {
          title: {
            fi: "IN ENGLISH",
            en: "SUOMEKSI",
          },
          action: "changeLang",
        },
      ],
      brown: "#251104",
    };
  },
  watch: {
    group() {
      this.drawer = false;
    },
    loginStatus(val) {
      if (val === true) {
        this.menuItems = [...this.loggedIn];
      } else {
        this.menuItems = [...this.loggedOut];
      }
    },
  },
  methods: {
    changeLang() {
      this.selected = this.selected !== "fi" ? "fi" : "en";
      localStorage.setItem("lang", this.selected);
      this.$store.commit("changeLocale", this.selected);
    },
  },
  computed: {
    locale() {
      return this.$store.state.locale;
    },
    loginStatus() {
      return this.$store.state.loginStatus;
    },
    usecases() {
      return this.$store.state.usecases;
    },
    drawerMenuItems() {
      const mapped = this.menuItems.map(item => {
        return [item].concat(item.dropdown ? item.dropdown : [])
      });
      return mapped.flat()
    }
  },
  created() {
    if (this.loginStatus == true) {
      this.menuItems = [...this.loggedIn];
    } else {
      this.menuItems = [...this.loggedOut];
    }

    const lang = localStorage.getItem("lang", this.selected);

    if (!lang) {
      localStorage.setItem("lang", "fi");
      this.$store.commit("changeLocale", "fi");
      this.selected = "fi";
    } else {
      this.$store.commit("changeLocale", lang);
      this.selected = lang;
    }
  },
};
</script>
